@font-face {
    font-family: 'Caveat';
    src: url(../../assets/fonts/Caveat-Bold.ttf) format("truetype");
    font-weight:600;
  }

  @font-face {
    font-family: 'FrancoisOne';
    src: url(../../assets/fonts/FrancoisOne-Regular.ttf) format("truetype");
    font-weight:400;
  }