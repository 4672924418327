//
// Variables
// --------------------------------------------------

//== Colors
//
//## Gray and brand colors for use across Theme.
//---------------------------------------------------

$signature-bgcolor: #cc3a71;
$signature-successbg: #28a745;
// Grayscale
$gray-darker:            #374250;
$gray-dark:            	 #606975;
$gray:                   #9da9b9;
$gray-light:             #e1e7ec;
$gray-lighter:           #f5f5f5;
$white-color:            #ffffff;
$black-color:            #000000;
$blue-dark:               #13305e;

// Brand colors
$brand-primary:         $signature-bgcolor;
$brand-info:		        #50c6e9;
$brand-success:         $signature-successbg;
$brand-warning:         #ffb74f;
$brand-danger:          #ff5252;

// Body
$body-bg: 							white;//$gray-lighter;
$body-color: 						$gray-dark;

// Inline links
$link-color: 						$brand-primary;
$link-hover-color: 			$brand-primary;

// Hedings
$headings-color:	 			$gray-darker;

// Blockquote
$quote-text-color:      $body-color;
$quote-mark-color:      $gray;
$quote-author-color:    $gray;

// Borders
$border-color:          $gray-light;
$border-light-color:    rgba($white-color, .12);

// Highlight color
$highlight-color:       #fff8b0;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
// -------------------------------------------------------

$font-family-base: 			  'Maven Pro', Helvetica, Arial, sans-serif;
$font-family-headings: 	  'Maven Pro', Helvetica, Arial, sans-serif;//inherit;

// Font sizes
$font-size-base:          16px;
$font-size-lead:          floor(($font-size-base * 1.125)); // ~18px
$font-size-sm: 				    floor(($font-size-base * .875)); // ~14px
$font-size-xs:         		floor(($font-size-base * .8125)); // ~13px

// Headings
$font-size-h1:            floor(($font-size-base * 2.25)); // ~36px
$font-size-h2:            floor(($font-size-base * 1.875)); // ~30px
$font-size-h3:            floor(($font-size-base * 1.5)); // ~24px
$font-size-h4:            floor(($font-size-base * 1.25)); // ~20px
$font-size-h5:            $font-size-lead; // ~18px
$font-size-h6:            $font-size-base; // ~16px

// Display headings
$display-1:               floor(($font-size-base * 4.5)); // ~72px
$display-2:               floor(($font-size-base * 3.75)); // ~60px
$display-3:               floor(($font-size-base * 3.125)); // ~50px
$display-4:               floor(($font-size-base * 2.5)); // ~40px

// Font styles
$font-style-base:         normal;
$font-style-headings:     normal;

// Font weights
$font-weight-base:         normal;
$font-weight-headings:     500;

// Text transforms
$text-transform-base:     none;
$text-transform-headings: none;

// Line heights
$line-height-base:       1.5;
$line-height-h1:         1.15;
$line-height-h2:         1.2;
$line-height-h3:         1.25;
$line-height-h4:         1.3;
$line-height-h5:         1.35;
$line-height-h6:         1.4;
$line-height-display:    1.15;


//== Grid Vertical Step
// -------------------------------------------------------

$grid-vertical-step:     24px;


//== Border Radius
// -------------------------------------------------------

$border-radius-lg: 		7px;
$border-radius-base: 	5px;
$border-radius-sm: 		3px;


//== Forms
// -------------------------------------------------------

$input-height-lg:							54px;
$input-height:								44px;
$input-height-sm:							36px;
$input-color-placeholder:     $gray;


//== Buttons
// -------------------------------------------------------

$btn-font-size: 							$font-size-sm;
$btn-sm-font-size: 						$font-size-xs;
$btn-text-transform:          uppercase;
$btn-font-weight: 						500;
$btn-text-color:              $gray-dark;

$btn-height: 									44px;
$btn-lg-height: 							54px;
$btn-sm-height: 							36px;

$btn-margin:                  floor($grid-vertical-step / 2); //~12px
$social-btn-size: 						$font-size-xs;


$btn-addtocart-bg: $signature-successbg;
$btn-addtocart-fg: white;

//== Tables
// -------------------------------------------------------

$table-border-color: 					$border-color;
$table-cell-padding:					15px;
$table-bg-accent: 						$gray-lighter;


//== Navbar / Logo
//

$navbar-min-height: 			84px;
$navbar-stuck-shadow:     0 3px 25px 0 rgba(darken($gray-darker, 4%), .22);
$logo-width: 				      129px;

// Navbar Tools
$navbar-tools-color:          $gray-dark;
$navbar-tools-size:           $btn-height;
$tools-icon-size:             17px;
//== Navigation
//

$nav-link-font-size: 				$font-size-sm; // 14px
$nav-link-font-weight: 			500;
$nav-link-color: 						$gray-dark;
$nav-link-hover-color: 			$brand-primary;
$nav-link-active-color: 		$brand-primary;

// Sub Menu / Mega Menu
$sub-menu-width:            200px;
$sub-menu-shadow:           0 7px 22px -5px rgba(darken($gray-darker, 4%), .2);

//== Widgets
//

$sidebar-max-width:          400px;
$widget-title-color:         $gray;
$widget-title-font-size:     $font-size-sm;
$widget-link-font-size:      $font-size-sm;
$widget-meta-color:          $gray;
$widget-meta-font-size:      floor(($font-size-base / 1.33)); //~12px
$widget-entry-thumb-size:    50px;

// Tags
$widget-tags-height:              28px;
$widget-tags-font-size:           $font-size-xs;
$widget-tags-color:               $nav-link-color;
$widget-tags-hover-bg:            $gray-lighter;
$widget-tags-active-bg:           $nav-link-active-color;
$widget-tags-active-border-color: $nav-link-active-color;
$widget-tags-active-color:        $white-color;

//== Media queries (responsive breackpoints)
//## Descktop, Tablet, Mobile

$screen-xl: 1200px;
$screen-lg: 991px;
$screen-md: 768px;
$screen-sm: 576px;
$screen-xs: 360px;

$nav-collapse:     1070px;

//footer
$footer-bg: $signature-bgcolor;
$footer-text-h: white;
$footer-text: white;
$footer-text-copyright: rgba($white-color, .5);

//Claim
$claim-bg: $signature-bgcolor;
$claim-fg: white;

$thumbnail-bg : white;

$navbar-tools-size: 44px;
$navbar-height: 4.5rem;
$content-width: 1200px;