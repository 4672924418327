@font-face{
    font-family:feather;
    src:url(../../assets/fonts/feather-webfont.eot);
    src:url(../../assets/fonts/feather-webfont.eot?#iefix) format("embedded-opentype"),
        url(../../assets/fonts/feather-webfont.woff) format("woff"),
        url(../../assets/fonts/feather-webfont.ttf) format("truetype"),
        url(../../assets/fonts/feather-webfont.svg#feather) format("svg");
    font-weight:400;
    font-style:normal
}


@font-face{
    font-family:Pe-icon-7-stroke;
    src:url(../../assets/fonts/Pe-icon-7-stroke.eot?d7yf1v);
    src:url(../../assets/fonts/Pe-icon-7-stroke.eot?#iefixd7yf1v) format('embedded-opentype'),
    url(../../assets/fonts/Pe-icon-7-stroke.woff?d7yf1v) format('woff'),
    url(../../assets/fonts/Pe-icon-7-stroke.ttf?d7yf1v) format('truetype'),
    url(../../assets/fonts/Pe-icon-7-stroke.svg?d7yf1v#Pe-icon-7-stroke) format('svg');
    font-weight:400;
    font-style:normal
}

@font-face{
	font-family:socicon;
	src:url(../../assets/fonts/socicon.eot);
    src:url(../../assets/fonts/socicon.eot?#iefix) format("embedded-opentype"),
        url(../../assets/fonts/socicon.woff) format("woff"),
        url(../../assets/fonts/socicon.ttf) format("truetype"),
        url(../../assets/fonts/socicon.svg#socicon) format("svg");
	font-weight:400;
	font-style:normal
}

[class*=" icon-"],[class^=icon-],[data-icon]:before{
    display:inline-block;
    vertical-align: middle;
    font-family:feather;
    font-style:normal;
    font-weight:400;
    font-variant:normal;
    text-transform:none;
    speak:none;
    line-height:1;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale
}
[data-icon]:before{
    content:attr(data-icon)
}
.icon-eye:before{
    content:"\e000"
}
.icon-paper-clip:before{
    content:"\e001"
}
.icon-mail:before{
    content:"\e002"
}
.icon-toggle:before{
    content:"\e003"
}
.icon-layout:before{
    content:"\e004"
}
.icon-link:before{
    content:"\e005"
}
.icon-bell:before{
    content:"\e006"
}
.icon-lock:before{
    content:"\e007"
}
.icon-unlock:before{
    content:"\e008"
}
.icon-ribbon:before{
    content:"\e009"
}
.icon-image:before{
    content:"\e010"
}
.icon-signal:before{
    content:"\e011"
}
.icon-target:before{
    content:"\e012"
}
.icon-clipboard:before{
    content:"\e013"
}
.icon-clock:before{
    content:"\e014"
}
.icon-watch:before{
    content:"\e015"
}
.icon-air-play:before{
    content:"\e016"
}
.icon-camera:before{
    content:"\e017"
}
.icon-video:before{
    content:"\e018"
}
.icon-disc:before{
    content:"\e019"
}
.icon-printer:before{
    content:"\e020"
}
.icon-monitor:before{
    content:"\e021"
}
.icon-server:before{
    content:"\e022"
}
.icon-cog:before{
    content:"\e023"
}
.icon-heart:before{
    content:"\e024"
}
.icon-paragraph:before{
    content:"\e025"
}
.icon-align-justify:before{
    content:"\e026"
}
.icon-align-left:before{
    content:"\e027"
}
.icon-align-center:before{
    content:"\e028"
}
.icon-align-right:before{
    content:"\e029"
}
.icon-book:before{
    content:"\e030"
}
.icon-layers:before{
    content:"\e031"
}
.icon-stack:before{
    content:"\e032"
}
.icon-stack-2:before{
    content:"\e033"
}
.icon-paper:before{
    content:"\e034"
}
.icon-paper-stack:before{
    content:"\e035"
}
.icon-search:before{
    content:"\e036"
}
.icon-zoom-in:before{
    content:"\e037"
}
.icon-zoom-out:before{
    content:"\e038"
}
.icon-reply:before{
    content:"\e039"
}
.icon-circle-plus:before{
    content:"\e040"
}
.icon-circle-minus:before{
    content:"\e041"
}
.icon-circle-check:before{
    content:"\e042"
}
.icon-circle-cross:before{
    content:"\e043"
}
.icon-square-plus:before{
    content:"\e044"
}
.icon-square-minus:before{
    content:"\e045"
}
.icon-square-check:before{
    content:"\e046"
}
.icon-square-cross:before{
    content:"\e047"
}
.icon-microphone:before{
    content:"\e048"
}
.icon-record:before{
    content:"\e049"
}
.icon-skip-back:before{
    content:"\e050"
}
.icon-rewind:before{
    content:"\e051"
}
.icon-play:before{
    content:"\e052"
}
.icon-pause:before{
    content:"\e053"
}
.icon-stop:before{
    content:"\e054"
}
.icon-fast-forward:before{
    content:"\e055"
}
.icon-skip-forward:before{
    content:"\e056"
}
.icon-shuffle:before{
    content:"\e057"
}
.icon-repeat:before{
    content:"\e058"
}
.icon-folder:before{
    content:"\e059"
}
.icon-umbrella:before{
    content:"\e060"
}
.icon-moon:before{
    content:"\e061"
}
.icon-thermometer:before{
    content:"\e062"
}
.icon-drop:before{
    content:"\e063"
}
.icon-sun:before{
    content:"\e064"
}
.icon-cloud:before{
    content:"\e065"
}
.icon-cloud-upload:before{
    content:"\e066"
}
.icon-cloud-download:before{
    content:"\e067"
}
.icon-upload:before{
    content:"\e068"
}
.icon-download:before{
    content:"\e069"
}
.icon-location:before{
    content:"\e070"
}
.icon-location-2:before{
    content:"\e071"
}
.icon-map:before{
    content:"\e072"
}
.icon-battery:before{
    content:"\e073"
}
.icon-head:before{
    content:"\e074"
}
.icon-briefcase:before{
    content:"\e075"
}
.icon-speech-bubble:before{
    content:"\e076"
}
.icon-anchor:before{
    content:"\e077"
}
.icon-globe:before{
    content:"\e078"
}
.icon-box:before{
    content:"\e079"
}
.icon-reload:before{
    content:"\e080"
}
.icon-share:before{
    content:"\e081"
}
.icon-marquee:before{
    content:"\e082"
}
.icon-marquee-plus:before{
    content:"\e083"
}
.icon-marquee-minus:before{
    content:"\e084"
}
.icon-tag:before{
    content:"\e085"
}
.icon-power:before{
    content:"\e086"
}
.icon-command:before{
    content:"\e087"
}
.icon-alt:before{
    content:"\e088"
}
.icon-esc:before{
    content:"\e089"
}
.icon-bar-graph:before{
    content:"\e090"
}
.icon-bar-graph-2:before{
    content:"\e091"
}
.icon-pie-graph:before{
    content:"\e092"
}
.icon-star:before{
    content:"\e093"
}
.icon-arrow-left:before{
    content:"\e094"
}
.icon-arrow-right:before{
    content:"\e095"
}
.icon-arrow-up:before{
    content:"\e096"
}
.icon-arrow-down:before{
    content:"\e097"
}
.icon-volume:before{
    content:"\e098"
}
.icon-mute:before{
    content:"\e099"
}
.icon-content-right:before{
    content:"\e100"
}
.icon-content-left:before{
    content:"\e101"
}
.icon-grid:before{
    content:"\e102"
}
.icon-grid-2:before{
    content:"\e103"
}
.icon-columns:before{
    content:"\e104"
}
.icon-loader:before{
    content:"\e105"
}
.icon-bag:before{
    content:"\e106"
}
.icon-ban:before{
    content:"\e107"
}
.icon-flag:before{
    content:"\e108"
}
.icon-trash:before{
    content:"\e109"
}
.icon-expand:before{
    content:"\e110"
}
.icon-contract:before{
    content:"\e111"
}
.icon-maximize:before{
    content:"\e112"
}
.icon-minimize:before{
    content:"\e113"
}
.icon-plus:before{
    content:"\e114"
}
.icon-minus:before{
    content:"\e115"
}
.icon-check:before{
    content:"\e116"
}
.icon-cross:before{
    content:"\e117"
}
.icon-move:before{
    content:"\e118"
}
.icon-delete:before{
    content:"\e119"
}
.icon-menu:before{
    content:"\e120"
}
.icon-archive:before{
    content:"\e121"
}
.icon-inbox:before{
    content:"\e122"
}
.icon-outbox:before{
    content:"\e123"
}
.icon-file:before{
    content:"\e124"
}
.icon-file-add:before{
    content:"\e125"
}
.icon-file-subtract:before{
    content:"\e126"
}
.icon-help:before{
    content:"\e127"
}
.icon-open:before{
    content:"\e128"
}
.icon-ellipsis:before{
    content:"\e129"
}





[class*=" pe-7s-"],[class^=pe-7s-]{
    font-family:Pe-icon-7-stroke;
    speak:none;
    font-style:normal;
    font-weight:400;
    font-variant:normal;
    text-transform:none;
    line-height:1;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale
}
.pe-7s-album:before{
    content:"\e6aa"
}
.pe-7s-arc:before{
    content:"\e6ab"
}
.pe-7s-back-2:before{
    content:"\e6ac"
}
.pe-7s-bandaid:before{
    content:"\e6ad"
}
.pe-7s-car:before{
    content:"\e6ae"
}
.pe-7s-diamond:before{
    content:"\e6af"
}
.pe-7s-door-lock:before{
    content:"\e6b0"
}
.pe-7s-eyedropper:before{
    content:"\e6b1"
}
.pe-7s-female:before{
    content:"\e6b2"
}
.pe-7s-gym:before{
    content:"\e6b3"
}
.pe-7s-hammer:before{
    content:"\e6b4"
}
.pe-7s-headphones:before{
    content:"\e6b5"
}
.pe-7s-helm:before{
    content:"\e6b6"
}
.pe-7s-hourglass:before{
    content:"\e6b7"
}
.pe-7s-leaf:before{
    content:"\e6b8"
}
.pe-7s-magic-wand:before{
    content:"\e6b9"
}
.pe-7s-male:before{
    content:"\e6ba"
}
.pe-7s-map-2:before{
    content:"\e6bb"
}
.pe-7s-next-2:before{
    content:"\e6bc"
}
.pe-7s-paint-bucket:before{
    content:"\e6bd"
}
.pe-7s-pendrive:before{
    content:"\e6be"
}
.pe-7s-photo:before{
    content:"\e6bf"
}
.pe-7s-piggy:before{
    content:"\e6c0"
}
.pe-7s-plugin:before{
    content:"\e6c1"
}
.pe-7s-refresh-2:before{
    content:"\e6c2"
}
.pe-7s-rocket:before{
    content:"\e6c3"
}
.pe-7s-settings:before{
    content:"\e6c4"
}
.pe-7s-shield:before{
    content:"\e6c5"
}
.pe-7s-smile:before{
    content:"\e6c6"
}
.pe-7s-usb:before{
    content:"\e6c7"
}
.pe-7s-vector:before{
    content:"\e6c8"
}
.pe-7s-wine:before{
    content:"\e6c9"
}
.pe-7s-cloud-upload:before{
    content:"\e68a"
}
.pe-7s-cash:before{
    content:"\e68c"
}
.pe-7s-close:before{
    content:"\e680"
}
.pe-7s-bluetooth:before{
    content:"\e68d"
}
.pe-7s-cloud-download:before{
    content:"\e68b"
}
.pe-7s-way:before{
    content:"\e68e"
}
.pe-7s-close-circle:before{
    content:"\e681"
}
.pe-7s-id:before{
    content:"\e68f"
}
.pe-7s-angle-up:before{
    content:"\e682"
}
.pe-7s-wristwatch:before{
    content:"\e690"
}
.pe-7s-angle-up-circle:before{
    content:"\e683"
}
.pe-7s-world:before{
    content:"\e691"
}
.pe-7s-angle-right:before{
    content:"\e684"
}
.pe-7s-volume:before{
    content:"\e692"
}
.pe-7s-angle-right-circle:before{
    content:"\e685"
}
.pe-7s-users:before{
    content:"\e693"
}
.pe-7s-angle-left:before{
    content:"\e686"
}
.pe-7s-user-female:before{
    content:"\e694"
}
.pe-7s-angle-left-circle:before{
    content:"\e687"
}
.pe-7s-up-arrow:before{
    content:"\e695"
}
.pe-7s-angle-down:before{
    content:"\e688"
}
.pe-7s-switch:before{
    content:"\e696"
}
.pe-7s-angle-down-circle:before{
    content:"\e689"
}
.pe-7s-scissors:before{
    content:"\e697"
}
.pe-7s-wallet:before{
    content:"\e600"
}
.pe-7s-safe:before{
    content:"\e698"
}
.pe-7s-volume2:before{
    content:"\e601"
}
.pe-7s-volume1:before{
    content:"\e602"
}
.pe-7s-voicemail:before{
    content:"\e603"
}
.pe-7s-video:before{
    content:"\e604"
}
.pe-7s-user:before{
    content:"\e605"
}
.pe-7s-upload:before{
    content:"\e606"
}
.pe-7s-unlock:before{
    content:"\e607"
}
.pe-7s-umbrella:before{
    content:"\e608"
}
.pe-7s-trash:before{
    content:"\e609"
}
.pe-7s-tools:before{
    content:"\e60a"
}
.pe-7s-timer:before{
    content:"\e60b"
}
.pe-7s-ticket:before{
    content:"\e60c"
}
.pe-7s-target:before{
    content:"\e60d"
}
.pe-7s-sun:before{
    content:"\e60e"
}
.pe-7s-study:before{
    content:"\e60f"
}
.pe-7s-stopwatch:before{
    content:"\e610"
}
.pe-7s-star:before{
    content:"\e611"
}
.pe-7s-speaker:before{
    content:"\e612"
}
.pe-7s-signal:before{
    content:"\e613"
}
.pe-7s-shuffle:before{
    content:"\e614"
}
.pe-7s-shopbag:before{
    content:"\e615"
}
.pe-7s-share:before{
    content:"\e616"
}
.pe-7s-server:before{
    content:"\e617"
}
.pe-7s-search:before{
    content:"\e618"
}
.pe-7s-film:before{
    content:"\e6a5"
}
.pe-7s-science:before{
    content:"\e619"
}
.pe-7s-disk:before{
    content:"\e6a6"
}
.pe-7s-ribbon:before{
    content:"\e61a"
}
.pe-7s-repeat:before{
    content:"\e61b"
}
.pe-7s-refresh:before{
    content:"\e61c"
}
.pe-7s-add-user:before{
    content:"\e6a9"
}
.pe-7s-refresh-cloud:before{
    content:"\e61d"
}
.pe-7s-paperclip:before{
    content:"\e69c"
}
.pe-7s-radio:before{
    content:"\e61e"
}
.pe-7s-note2:before{
    content:"\e69d"
}
.pe-7s-print:before{
    content:"\e61f"
}
.pe-7s-network:before{
    content:"\e69e"
}
.pe-7s-prev:before{
    content:"\e620"
}
.pe-7s-mute:before{
    content:"\e69f"
}
.pe-7s-power:before{
    content:"\e621"
}
.pe-7s-medal:before{
    content:"\e6a0"
}
.pe-7s-portfolio:before{
    content:"\e622"
}
.pe-7s-like2:before{
    content:"\e6a1"
}
.pe-7s-plus:before{
    content:"\e623"
}
.pe-7s-left-arrow:before{
    content:"\e6a2"
}
.pe-7s-play:before{
    content:"\e624"
}
.pe-7s-key:before{
    content:"\e6a3"
}
.pe-7s-plane:before{
    content:"\e625"
}
.pe-7s-joy:before{
    content:"\e6a4"
}
.pe-7s-photo-gallery:before{
    content:"\e626"
}
.pe-7s-pin:before{
    content:"\e69b"
}
.pe-7s-phone:before{
    content:"\e627"
}
.pe-7s-plug:before{
    content:"\e69a"
}
.pe-7s-pen:before{
    content:"\e628"
}
.pe-7s-right-arrow:before{
    content:"\e699"
}
.pe-7s-paper-plane:before{
    content:"\e629"
}
.pe-7s-delete-user:before{
    content:"\e6a7"
}
.pe-7s-paint:before{
    content:"\e62a"
}
.pe-7s-bottom-arrow:before{
    content:"\e6a8"
}
.pe-7s-notebook:before{
    content:"\e62b"
}
.pe-7s-note:before{
    content:"\e62c"
}
.pe-7s-next:before{
    content:"\e62d"
}
.pe-7s-news-paper:before{
    content:"\e62e"
}
.pe-7s-musiclist:before{
    content:"\e62f"
}
.pe-7s-music:before{
    content:"\e630"
}
.pe-7s-mouse:before{
    content:"\e631"
}
.pe-7s-more:before{
    content:"\e632"
}
.pe-7s-moon:before{
    content:"\e633"
}
.pe-7s-monitor:before{
    content:"\e634"
}
.pe-7s-micro:before{
    content:"\e635"
}
.pe-7s-menu:before{
    content:"\e636"
}
.pe-7s-map:before{
    content:"\e637"
}
.pe-7s-map-marker:before{
    content:"\e638"
}
.pe-7s-mail:before{
    content:"\e639"
}
.pe-7s-mail-open:before{
    content:"\e63a"
}
.pe-7s-mail-open-file:before{
    content:"\e63b"
}
.pe-7s-magnet:before{
    content:"\e63c"
}
.pe-7s-loop:before{
    content:"\e63d"
}
.pe-7s-look:before{
    content:"\e63e"
}
.pe-7s-lock:before{
    content:"\e63f"
}
.pe-7s-lintern:before{
    content:"\e640"
}
.pe-7s-link:before{
    content:"\e641"
}
.pe-7s-like:before{
    content:"\e642"
}
.pe-7s-light:before{
    content:"\e643"
}
.pe-7s-less:before{
    content:"\e644"
}
.pe-7s-keypad:before{
    content:"\e645"
}
.pe-7s-junk:before{
    content:"\e646"
}
.pe-7s-info:before{
    content:"\e647"
}
.pe-7s-home:before{
    content:"\e648"
}
.pe-7s-help2:before{
    content:"\e649"
}
.pe-7s-help1:before{
    content:"\e64a"
}
.pe-7s-graph3:before{
    content:"\e64b"
}
.pe-7s-graph2:before{
    content:"\e64c"
}
.pe-7s-graph1:before{
    content:"\e64d"
}
.pe-7s-graph:before{
    content:"\e64e"
}
.pe-7s-global:before{
    content:"\e64f"
}
.pe-7s-gleam:before{
    content:"\e650"
}
.pe-7s-glasses:before{
    content:"\e651"
}
.pe-7s-gift:before{
    content:"\e652"
}
.pe-7s-folder:before{
    content:"\e653"
}
.pe-7s-flag:before{
    content:"\e654"
}
.pe-7s-filter:before{
    content:"\e655"
}
.pe-7s-file:before{
    content:"\e656"
}
.pe-7s-expand1:before{
    content:"\e657"
}
.pe-7s-exapnd2:before{
    content:"\e658"
}
.pe-7s-edit:before{
    content:"\e659"
}
.pe-7s-drop:before{
    content:"\e65a"
}
.pe-7s-drawer:before{
    content:"\e65b"
}
.pe-7s-download:before{
    content:"\e65c"
}
.pe-7s-display2:before{
    content:"\e65d"
}
.pe-7s-display1:before{
    content:"\e65e"
}
.pe-7s-diskette:before{
    content:"\e65f"
}
.pe-7s-date:before{
    content:"\e660"
}
.pe-7s-cup:before{
    content:"\e661"
}
.pe-7s-culture:before{
    content:"\e662"
}
.pe-7s-crop:before{
    content:"\e663"
}
.pe-7s-credit:before{
    content:"\e664"
}
.pe-7s-copy-file:before{
    content:"\e665"
}
.pe-7s-config:before{
    content:"\e666"
}
.pe-7s-compass:before{
    content:"\e667"
}
.pe-7s-comment:before{
    content:"\e668"
}
.pe-7s-coffee:before{
    content:"\e669"
}
.pe-7s-cloud:before{
    content:"\e66a"
}
.pe-7s-clock:before{
    content:"\e66b"
}
.pe-7s-check:before{
    content:"\e66c"
}
.pe-7s-chat:before{
    content:"\e66d"
}
.pe-7s-cart:before{
    content:"\e66e"
}
.pe-7s-camera:before{
    content:"\e66f"
}
.pe-7s-call:before{
    content:"\e670"
}
.pe-7s-calculator:before{
    content:"\e671"
}
.pe-7s-browser:before{
    content:"\e672"
}
.pe-7s-box2:before{
    content:"\e673"
}
.pe-7s-box1:before{
    content:"\e674"
}
.pe-7s-bookmarks:before{
    content:"\e675"
}
.pe-7s-bicycle:before{
    content:"\e676"
}
.pe-7s-bell:before{
    content:"\e677"
}
.pe-7s-battery:before{
    content:"\e678"
}
.pe-7s-ball:before{
    content:"\e679"
}
.pe-7s-back:before{
    content:"\e67a"
}
.pe-7s-attention:before{
    content:"\e67b"
}
.pe-7s-anchor:before{
    content:"\e67c"
}
.pe-7s-albums:before{
    content:"\e67d"
}
.pe-7s-alarm:before{
    content:"\e67e"
}
.pe-7s-airplay:before{
    content:"\e67f"
}




[class*=" socicon-"]:before,[class^=socicon-]:before,[data-icon]:before{
	font-family:socicon!important;
	font-style:normal!important;
	font-weight:400!important;
	font-variant:normal!important;
	text-transform:none!important;
	speak:none;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale
}
[data-icon]:before{
	content:attr(data-icon)
}
.socicon-modelmayhem:before{
	content:"\e000"
}
.socicon-mixcloud:before{
	content:"\e001"
}
.socicon-drupal:before{
	content:"\e002"
}
.socicon-swarm:before{
	content:"\e003"
}
.socicon-istock:before{
	content:"\e004"
}
.socicon-yammer:before{
	content:"\e005"
}
.socicon-ello:before{
	content:"\e006"
}
.socicon-stackoverflow:before{
	content:"\e007"
}
.socicon-persona:before{
	content:"\e008"
}
.socicon-triplej:before{
	content:"\e009"
}
.socicon-houzz:before{
	content:"\e00a"
}
.socicon-rss:before{
	content:"\e00b"
}
.socicon-paypal:before{
	content:"\e00c"
}
.socicon-odnoklassniki:before{
	content:"\e00d"
}
.socicon-airbnb:before{
	content:"\e00e"
}
.socicon-periscope:before{
	content:"\e00f"
}
.socicon-outlook:before{
	content:"\e010"
}
.socicon-coderwall:before{
	content:"\e011"
}
.socicon-tripadvisor:before{
	content:"\e012"
}
.socicon-appnet:before{
	content:"\e013"
}
.socicon-goodreads:before{
	content:"\e014"
}
.socicon-tripit:before{
	content:"\e015"
}
.socicon-lanyrd:before{
	content:"\e016"
}
.socicon-slideshare:before{
	content:"\e017"
}
.socicon-buffer:before{
	content:"\e018"
}
.socicon-disqus:before{
	content:"\e019"
}
.socicon-vkontakte:before{
	content:"\e01a"
}
.socicon-whatsapp:before{
	content:"\e01b"
}
.socicon-patreon:before{
	content:"\e01c"
}
.socicon-storehouse:before{
	content:"\e01d"
}
.socicon-pocket:before{
	content:"\e01e"
}
.socicon-mail:before{
	content:"\e01f"
}
.socicon-blogger:before{
	content:"\e020"
}
.socicon-technorati:before{
	content:"\e021"
}
.socicon-reddit:before{
	content:"\e022"
}
.socicon-dribbble:before{
	content:"\e023"
}
.socicon-stumbleupon:before{
	content:"\e024"
}
.socicon-digg:before{
	content:"\e025"
}
.socicon-envato:before{
	content:"\e026"
}
.socicon-behance:before{
	content:"\e027"
}
.socicon-delicious:before{
	content:"\e028"
}
.socicon-deviantart:before{
	content:"\e029"
}
.socicon-forrst:before{
	content:"\e02a"
}
.socicon-play:before{
	content:"\e02b"
}
.socicon-zerply:before{
	content:"\e02c"
}
.socicon-wikipedia:before{
	content:"\e02d"
}
.socicon-apple:before{
	content:"\e02e"
}
.socicon-flattr:before{
	content:"\e02f"
}
.socicon-github:before{
	content:"\e030"
}
.socicon-renren:before{
	content:"\e031"
}
.socicon-friendfeed:before{
	content:"\e032"
}
.socicon-newsvine:before{
	content:"\e033"
}
.socicon-identica:before{
	content:"\e034"
}
.socicon-bebo:before{
	content:"\e035"
}
.socicon-zynga:before{
	content:"\e036"
}
.socicon-steam:before{
	content:"\e037"
}
.socicon-xbox:before{
	content:"\e038"
}
.socicon-windows:before{
	content:"\e039"
}
.socicon-qq:before{
	content:"\e03a"
}
.socicon-douban:before{
	content:"\e03b"
}
.socicon-meetup:before{
	content:"\e03c"
}
.socicon-playstation:before{
	content:"\e03d"
}
.socicon-android:before{
	content:"\e03e"
}
.socicon-snapchat:before{
	content:"\e03f"
}
.socicon-twitter:before{
	content:"\e040"
}
.socicon-facebook:before{
	content:"\e041"
}
.socicon-googleplus:before{
	content:"\e042"
}
.socicon-pinterest:before{
	content:"\e043"
}
.socicon-foursquare:before{
	content:"\e044"
}
.socicon-yahoo:before{
	content:"\e045"
}
.socicon-skype:before{
	content:"\e046"
}
.socicon-yelp:before{
	content:"\e047"
}
.socicon-feedburner:before{
	content:"\e048"
}
.socicon-linkedin:before{
	content:"\e049"
}
.socicon-viadeo:before{
	content:"\e04a"
}
.socicon-xing:before{
	content:"\e04b"
}
.socicon-myspace:before{
	content:"\e04c"
}
.socicon-soundcloud:before{
	content:"\e04d"
}
.socicon-spotify:before{
	content:"\e04e"
}
.socicon-grooveshark:before{
	content:"\e04f"
}
.socicon-lastfm:before{
	content:"\e050"
}
.socicon-youtube:before{
	content:"\e051"
}
.socicon-vimeo:before{
	content:"\e052"
}
.socicon-dailymotion:before{
	content:"\e053"
}
.socicon-vine:before{
	content:"\e054"
}
.socicon-flickr:before{
	content:"\e055"
}
.socicon-500px:before{
	content:"\e056"
}
.socicon-wordpress:before{
	content:"\e058"
}
.socicon-tumblr:before{
	content:"\e059"
}
.socicon-twitch:before{
	content:"\e05a"
}
.socicon-8tracks:before{
	content:"\e05b"
}
.socicon-amazon:before{
	content:"\e05c"
}
.socicon-icq:before{
	content:"\e05d"
}
.socicon-smugmug:before{
	content:"\e05e"
}
.socicon-ravelry:before{
	content:"\e05f"
}
.socicon-weibo:before{
	content:"\e060"
}
.socicon-baidu:before{
	content:"\e061"
}
.socicon-angellist:before{
	content:"\e062"
}
.socicon-ebay:before{
	content:"\e063"
}
.socicon-imdb:before{
	content:"\e064"
}
.socicon-stayfriends:before{
	content:"\e065"
}
.socicon-residentadvisor:before{
	content:"\e066"
}
.socicon-google:before{
	content:"\e067"
}
.socicon-yandex:before{
	content:"\e068"
}
.socicon-sharethis:before{
	content:"\e069"
}
.socicon-bandcamp:before{
	content:"\e06a"
}
.socicon-itunes:before{
	content:"\e06b"
}
.socicon-deezer:before{
	content:"\e06c"
}
.socicon-telegram:before{
	content:"\e06e"
}
.socicon-openid:before{
	content:"\e06f"
}
.socicon-amplement:before{
	content:"\e070"
}
.socicon-viber:before{
	content:"\e071"
}
.socicon-zomato:before{
	content:"\e072"
}
.socicon-quora:before{
	content:"\e073"
}
.socicon-draugiem:before{
	content:"\e074"
}
.socicon-endomodo:before{
	content:"\e075"
}
.socicon-filmweb:before{
	content:"\e076"
}
.socicon-stackexchange:before{
	content:"\e077"
}
.socicon-wykop:before{
	content:"\e078"
}
.socicon-teamspeak:before{
	content:"\e079"
}
.socicon-teamviewer:before{
	content:"\e07a"
}
.socicon-ventrilo:before{
	content:"\e07b"
}
.socicon-younow:before{
	content:"\e07c"
}
.socicon-raidcall:before{
	content:"\e07d"
}
.socicon-mumble:before{
	content:"\e07e"
}
.socicon-medium:before{
	content:"\e06d"
}
.socicon-bebee:before{
	content:"\e07f"
}
.socicon-hitbox:before{
	content:"\e080"
}
.socicon-reverbnation:before{
	content:"\e081"
}
.socicon-formulr:before{
	content:"\e082"
}
.socicon-instagram:before{
	content:"\e057"
}
.socicon-battlenet:before{
	content:"\e083"
}


